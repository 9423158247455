





















































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CountryService from '@/services/CountryService';
import StateService from "@/services/StateService";
import CityService from "@/services/CityService";
import CommonUtil from "@/utilities/CommonUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { EditorControlView, AlertDialogView },
    data: () => ({
        countries: [],
        states: [],
		city: {},
		countryId: null,
		stateId: null,
        saving: false,
        loadingCountries: false,
		loadingStates: false
    })
})
export default class CityInfoView extends Mixins(InfoViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private editorControl = new EditorControlProperties();
    private countryService = new CountryService();
    private stateService = new StateService();
	private cityService = new CityService();

	public async mounted() {
		this.mode = this.$route.params.mode;
	
		if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            await this.load();
            await this.loadCountries();
			await this.loadStates();
        }
	}

    public async loadCountries() {
        this.$data.loadingCountries = true;

        try {
            const r = await this.countryService.get();
			const countries = r.data.countries;
            countries.forEach(e => e.text = `${e.code} - ${e.name}`);
            this.$data.countries = countries.sort((lhs, rhs) => lhs.code.localeCompare(rhs.code));
        } catch (e) {
            this.$data.countries = [];
        } finally {
            this.$data.loadingCountries = false;
        }
    }
	
	public async loadStates() {
		this.$data.loadingStates = true;
		
		try {
			if (this.$data.countryId) {
                const r = await this.stateService.get({
                    countryId: this.$data.countryId
                });
				const states = r.data.states;
				//states.forEach(e => e.text = `${e.code} - ${e.name}`);
				this.$data.states = states.sort((lhs, rhs) => lhs.code.localeCompare(rhs.code));
			} else {
				this.$data.states = [];
			}
		} catch (e) {
			this.$data.states = [];
		} finally {
			this.$data.loadingStates = false;
		}
	}

    public async load() {
        try {
            if (this.isCreate()) {
                this.$data.city = {};
				this.$data.countryId = null;
				this.$data.stateId = null;
            } else if (this.isModify()) {
                var id = this.$route.query.id;
                const r = await this.cityService.get({ id, loadState: true });
                if (r.data.cities.length === 0) {
                    throw new Error(this.$t("message.record-not-found").toString());
                }
                var city = r.data.cities[0];				
				this.$data.city = city;
				this.$data.countryId = city.state.countryId;
				this.$data.stateId = city.stateId;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }
	
    public async create() {
        await this.$router.push("/management/common/cities/create");
        this.mode = "create";
        await this.load();
		
		this.$data.states = [];
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const city = CommonUtil.clone(this.$data.city);
            city.code = (city.name ?? "").replace(" ", "-").toUpperCase();
            city.stateId = this.$data.stateId ?? 0;

            if (this.isCreate()) {			
                const r = await this.cityService.post(city);
                this.$data.city = r.data;
                await this.$router.push("/management/common/cities/modify?id=" + this.$data.city.id);
                this.mode = "modify";

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            } else if (this.isModify()) {
                const r = await this.cityService.put(city);
                this.$data.city = r.data;

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async remove() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.cityService.delete(this.$data.city);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

	public async countryChanged() {
		this.$data.stateId = null;		
		
		await this.loadStates();
	}
	
    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "create") {
            await this.create();
        } else if (button === "save") {
            await this.save();
        } else if (button === "cancel") {
            this.cancel();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "discard-changes" && button === this.$t("text.yes")) {
                await this.load();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted")) {
            await this.$router.push("/management/common/cities");
        }
    }
}
